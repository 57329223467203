@import "../theme/index";

.additional-information-color {
  background-color: $blackApprox;
}

.additional-information {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 10px;
  padding: 0 3% 0 3%;
  height: 90px;

  @include respond-to(tablets) {
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    height: 50px;
  }
  @include respond-to(desktop) {
    font-size: 20px;
    height: 100px;
  }
  @include respond-to(largedesktop) {
    font-size: 24px;
  }

  &__copyright {
    margin-top: 23px;
    color: $grey;
    @include respond-to(tablets) {
      margin-top: 20px;
    }
    @include respond-to(desktop) {
      margin-top: 35px;
    }
  }

  &__privacy-terms {
    margin-top: 10px;
    display: flex;
    color: $grey;
    @include respond-to(tablets) {
      margin-top: 20px;
    }
    @include respond-to(desktop) {
      margin-top: 35px;
    }
  }

  &__button {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    color: $grey;
    margin-top: 0;
    margin-bottom: auto;
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  &__separator {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}
