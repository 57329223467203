@import "../../components/theme/index";

.video {

  &__header {

  }

  &__body {
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
  }

  &__item {
    list-style: none;
    border: 1px solid #d2d2d2;
    padding: 5px;
    background: none;
    margin: 5px;
  }

  &__toolbar {
    height: 60px;
    background-color: $grey;
    padding: 10px;
    display: flex;
  }

  &__toolbar-start {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
  }

  &__toolbar-end {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__button {
    width: auto;
    font-weight: 500;
    min-height: 40px;
    line-height: 38px;
    margin: 0px 5px;
  }

  &__wrapper {
    height: 440px;
    padding-bottom: 10px;
    margin-bottom: 18px;

    @include respond-to(tablets) {
      height: 606px;
    }
  }

  &__footer {
    height: 78px;
    background-color: $grey;
    padding: 10px;
    display: flex;
  }

  &__footer-start {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
  }

  &__footer-end {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .loader__wrapper {
    margin: 0 auto 40px;
  }

  &__message {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    height: 100%;
    padding-top: 12px;
  }

  &__modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;
    max-height: 60vh;
    padding: 20px;
    overflow: auto;

    .modal {
      &__header {
        display: none;
      }

      &__body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 10px;
        height: 100%;

        .bots {
          @include respond-to(tablets) {
            width: 50%;
            padding: 17px;
            margin: 0;
          }
          @include respond-to(desktop) {
            width: 33.3%;
          }

          &__item {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__videocall {
    height: 100%;
    width: 100%
  }

  &__videos {
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__language {
    font-size: 24px;
    font-weight: bold;
  }

  &__language-selector {
    width: 120px;
  }

  &__image {
    height: 30px;
    width: 30px;
    margin: 5px 5px;
  }

  &__loading {
    height: 30px;
    width: 30px;
    margin: 5px 5px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
