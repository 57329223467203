@import "../theme/index";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .gritx-btn {

    &:hover:not(:disabled) {
      transition: color 0.3s;
      color: $white;
    }

    &:disabled {
      cursor: default;
      background-color: $grey;
    }

    &.gritx-btn-primary {
      background-color: $blue;

      &:active {
        background: darken($blue, 6%);
      }

      &:disabled {
        background-color: $grey;
        color: $alto;
      }
    }
  }
}

.container {
  max-width: 1920px;
  margin: 0 auto;

  &__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.main {
  margin-top: 100px;
  flex: 1 1 auto;
  overflow-x: hidden;
  @include respond-to(tablets) {
    padding-top: 25px;
  }
  @include respond-to(desktop) {
    padding-top: 50px;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}